<template>
  <v-container fluid class="pt-0 fill-height">
    <v-row justify="center" style="height: 100%; display: flex; flex-direction: column; flex-wrap: nowrap">
      <v-col cols="12" style="flex: 0">
        <v-card class="pb-3">
          <v-toolbar tile elevation="0" outlined dense style="border: none"
                     :height="$vuetify.breakpoint.xs ? 90 : 54">
            <template v-if="$vuetify.breakpoint.smAndUp">
              <v-toolbar-title class="font-weight-medium" style="font-size: 1.1rem">Реєстр показів за
                {{ current_month | formatDate('MMMM YYYY') }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items class="shrink">
                <v-row class="align-center">
                  <MonthComponent v-model="current_month" :class_="'mr-2'" :label="`Місяць`"
                                  @onMonthChange="onMonthChange"/>
                  <v-btn fab depressed small class="mr-2" @click.stop="createDocument">
                    <v-icon color="grey darken-2">
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-row>
              </v-toolbar-items>
            </template>
            <template v-else>
              <div style="width: 100%">
                <div class="d-flex mb-2">
                  <v-toolbar-title class="font-weight-medium" style="font-size: 1.3rem">
                    Реєстр показів за {{ current_month | formatDate('MMMM YYYY') }} року
                  </v-toolbar-title>
                </div>
                <div class="d-flex">
                  <MonthComponent v-model="current_month" :class_="'mr-2'" :label="`Місяць`"/>
                  <v-spacer></v-spacer>
                  <v-btn depressed small @click.stop="createDocument" height="36">
                    <v-icon color="grey darken-2">
                      mdi-plus
                    </v-icon>
                    Створити
                  </v-btn>
                </div>
              </div>
            </template>
          </v-toolbar>
          <div class="d-flex">
            <v-text-field class="px-4 pt-0" v-model="search"
                          hide-details placeholder="Введіть текст для пошуку"
                          color="grey darken-1" clearable clear-icon="mdi-close"
                          prepend-icon="mdi-text-box-search-outline"
                          @input="searchByText"
            />
            <v-switch hide-details color="success"
                      class="mt-1 pr-2"
                      style="font-size: .84rem !important;"
                      v-model="filter_by_user"
                      v-if="$vuetify.breakpoint.mdAndUp"
                      :label="filter_by_user ? 'Відбір по користувачу (включений)' : 'Відбір по користувачу (виключений)'"
            />
          </div>
          <v-switch hide-details color="success"
                    class="mt-2 pl-5"
                    style="font-size: .9rem"
                    v-model="filter_by_user"
                    v-if="$vuetify.breakpoint.smAndDown"
                    :label="filter_by_user ? 'Відбір по користувачу (включений)' : 'Відбір по користувачу (виключений)'"
          />
        </v-card>
      </v-col>
      <v-col style="flex: 0" class="py-0">
        <v-menu transition="slide-y-transition" bottom offset-y :close-on-content-click="close_sort">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                class="white"
                @click.stop="copySortedData"
                style="font-size: .85rem !important;"
                height="30px"
            >
              <v-icon size="18" left color="success" class="mr-2">mdi-sort</v-icon>
              Сортування
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <div>
                <div v-for="item in sortedDataCopy" :key="item.column" class="mb-2 d-flex">
                  <v-simple-checkbox v-model="item.active" color="success" :ripple="false" class="mr-2"/>
                  <div class="mr-2 align-self-center" style="font-size: .94rem; width: 140px">{{ item.text }}</div>

                  <v-btn small :ripple="false" fab icon class="mr-8" :disabled="!item.active"
                         @click.stop="item.desc = !item.desc">
                    <v-icon :color="item.desc ? 'error' : 'success'">
                      {{ item.desc ? "mdi-sort-descending" : 'mdi-sort-ascending' }}
                    </v-icon>
                  </v-btn>
                  <div>
                    <v-btn :disabled="!item.active" icon small @click="pushUp(item)">
                      <v-icon color="grey darken-2" size="20">mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn :disabled="!item.active" icon small @click="pushDown(item)">
                      <v-icon color="grey darken-2" size="20">mdi-arrow-down</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn small depressed block @click="sortHeaders">Застосувати</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="12" style="flex: 1">
        <template v-if="items.length">
          <v-card
              v-for="(item, idx) in filtered_items" :key="idx" tile elevation="2"
              class="mb-2 d-flex flex-wrap flex-row row-card"
              style="border-left: 3px solid #5bb55f"
              @click.stop="openDocument(item.id)"
          >
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
              <div>Дата створення</div>
              <div>{{ item.date | formatDate('DD.MM.YYYY HH:mm:ss') }}</div>
            </v-col>
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Дата показів</div>
              <div>{{ item.reading_date | formatDate }}</div>
            </v-col>
            <v-col cols="12" md="4" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Послуга</div>
              <div>
                <v-icon size="22"
                        :color="getAccountIcon(item.service_icon, 'color')"
                        class="mr-2">
                  {{ getAccountIcon(item.service_icon, 'icon') }}
                </v-icon>
                {{ item.service_name }}
              </div>
            </v-col>
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Об’єм / К-сть</div>
              <div>
                <span>{{ item.total_sum | formatToFixed }}</span> / <span>{{ item.total_row }}</span></div>
            </v-col>
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''">
              <div>Коментар</div>
              <div style="font-style: oblique">{{ item.comment ? item.comment : 'Коментар відсутній' }}</div>
            </v-col>
          </v-card>
        </template>
        <template v-else>
          <v-card style="height: 100%; display: flex; align-items: center; justify-content: center">
            <div class="wrapper align-center text-center">
              <v-icon size="200" color="success" style="opacity: .24">mdi-clock</v-icon>
              <div class="title grey--text text--darken-2 px-4">Документи для відображення відсутні <br> Спершу створіть
                документ
              </div>
            </div>
          </v-card>
        </template>
      </v-col>
      <v-col cols="12" style="flex: 0">
        <template>
          <div class="pay-table-pagination d-flex justify-start" v-if="items.length">
            <div class="pa-3 d-flex align-center justify-start">
              <v-btn text depressed icon class="mr-1"
                     @click.stop="pageStartOrEnd(true)"
                     :disabled="items_per_page > items.length || page === 1"
              >
                <v-icon>mdi-chevron-double-left</v-icon>
              </v-btn>
              <v-btn text depressed icon
                     @click.stop="changePage(true)"
                     :disabled="page === 1"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <div class="mx-2">
                <v-chip>
                  {{ `Сторінка: ${page} із ${pages}` }}
                </v-chip>
              </div>
              <v-menu top :close-on-click="true" :close-on-content-click="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" class="mr-2">
                    {{ `${items_per_page} на стор.` }}
                  </v-chip>
                </template>
                <v-list>
                  <v-list-item
                      v-for="(item, index) in items_per_page_list"
                      :key="index"
                      @click="setItemsPerPage(item)"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn text depressed icon class="mr-1"
                     @click.stop="changePage(false)"
                     :disabled="items_per_page > items.length || page === pages"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn text depressed icon
                     @click.stop="pageStartOrEnd(false)"
                     :disabled="page === pages"
              >
                <v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import readingDocumentAPI from '@/utils/axios/reading_document'
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
import {GET_CURRENT_MONTH} from "@/store/actions/work_month";
import {getAccountIcon} from "@/utils/icons";

export default {
  name: "ReadingDocumentList",
  components: {
    MonthComponent: () => import("@/components/MonthComponent")
  },
  computed: {
    ...mapGetters({
      current_month_: 'getCurrentMonth',
      current_month_legacy_: 'getCurrentMonthLegacy',
      user: "getUserId",
      legacy: 'isLegacy'
    }),
    filtered_items() {
      const pre = this.filter_by_user ? this.items.filter(item => item.user === this.user) : this.items
      return pre.slice((this.page - 1) * this.items_per_page, (this.page - 1) * this.items_per_page + this.items_per_page)
    }
  },
  data() {
    return {
      search: '',
      current_month: null,
      items: [],
      pages: 0,
      items_per_page: 9,
      page: 0,
      items_per_page_list: [9, 12, 14, 16, 18, 20, 25, 50],
      filter_by_user: true,
      sortedData: [
        {text: "Дата створення", column: 'date', desc: false, active: false},
        {text: "Дата показів", column: 'reading_date', desc: false, active: true},
        {text: "Послуга", column: 'service_name', desc: false, active: false},
        {text: "Сума", column: 'total_sum', desc: false, active: false},
      ],
      sortedDataCopy: null,
      close_sort: true
    }
  },
  methods: {
    pushUp(payload) {
      let current_index = this.sortedDataCopy.indexOf(payload)
      // let arr_length = this.grouping.length - 1
      let new_arr = []

      if (current_index === 0) {
        new_arr = [...this.sortedDataCopy.slice(1).concat([payload])]
      }
      //
      // if (current_index === 1) {
      //   new_arr = [...this.grouping.slice(1).concat(this.grouping.slice(0,1))]
      // }

      if (current_index >= 1) {
        new_arr = [
          ...this.sortedDataCopy.slice(0, current_index - 1).concat([payload])
              .concat(this.sortedDataCopy.slice(current_index - 1, current_index))
              .concat(this.sortedDataCopy.slice(current_index + 1))
        ]
      }
      this.sortedDataCopy = [...new_arr]
    },
    pushDown(payload) {
      let current_index = this.sortedDataCopy.indexOf(payload)
      let arr_length = this.sortedDataCopy.length - 1
      let new_arr = []

      if (current_index === arr_length) {
        new_arr = [...[payload].concat(this.sortedDataCopy.slice(0, current_index))]
      } else {
        new_arr = [
          ...this.sortedDataCopy.slice(0, current_index)
              .concat(this.sortedDataCopy.slice(current_index + 1, current_index + 2))
              .concat(this.sortedDataCopy.slice(current_index, current_index + 1))
              .concat(this.sortedDataCopy.slice(current_index + 2))
        ]
      }

      this.sortedDataCopy = [...new_arr]
    },
    saveSortToLocalStorage() {
      if (localStorage.getItem('reading_header_sort') === null) {
        localStorage.setItem('reading_header_sort', JSON.stringify(this.sortedData))
      } else {
        localStorage.removeItem('reading_header_sort')
        localStorage.setItem('reading_header_sort', JSON.stringify(this.sortedData))
      }
    },
    sortHeaders() {
      this.sortedData = JSON.parse(JSON.stringify(this.sortedDataCopy))
      this.close_sort = true
      this.saveSortToLocalStorage()
      this.fetchHeaders(this.search)
    },
    copySortedData() {
      this.close_sort = false
      this.sortedDataCopy = JSON.parse(JSON.stringify(this.sortedData))
    },
    searchByText(e) {
      if (e && e.length > 1) {
        this.fetchHeaders(e)
      }

      if (!e) {
        this.fetchHeaders(null)
      }
    },
    changePage(left) {
      if (left) {
        this.page = this.page === 1 ? 1 : this.page - 1
      } else {
        this.page = this.page === this.pages ? this.pages : this.page + 1
      }
    },
    setPageSplitter() {
      this.pages = Math.ceil(this.items.length / this.items_per_page)
      this.page = this.pages ? 1 : 0

      if (this.pages * this.items_per_page - this.items_per_page > this.items.length) {
        this.pages -= 1
      }
    },
    setItemsPerPage(per_page) {
      this.items_per_page = per_page
      this.setPageSplitter()
    },
    pageStartOrEnd(start) {
      if (start) {
        this.page = 1
      } else {
        this.page = this.pages
      }
    },
    getAccountIcon,
    onMonthChange(payload) {
      if (payload) {
        this.fetchHeaders()
      }
    },
    fetchHeaders(text = null) {
      const payload = {
        month: this.current_month,
        filter_by_user: this.filter_by_user,
        legacy: this.legacy
      }

      if (text) {
        payload.text = text
      }

      payload.sort = JSON.stringify(this.sortedData)

      readingDocumentAPI.get_headers(payload)
          .then(response => response.data)
          .then(data => {
            this.items = data
            this.setPageSplitter()
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    openDocument(payload) {
      if (this.legacy) {
        this.$router.push({name: 'ReadingDocumentHeaderLegacy', params: {header_id: payload}})
      } else {
        this.$router.push({name: 'ReadingDocumentHeader', params: {header_id: payload}})
      }
    },
    createDocument() {
      const current_month = this.legacy ? this.current_month_legacy_ : this.current_month_

      if (this.legacy) {
        this.$router.push({
          name: 'ReadingDocumentHeaderLegacy',
          params: {header_id: 'create'}, query: {month: current_month}
        })
      } else {
        this.$router.push({
          name: 'ReadingDocumentHeader',
          params: {header_id: 'create'},
          query: {month: current_month}
        })
      }
    }
  },
  created() {
    let header_sort = localStorage.getItem('reading_header_sort')
    if (header_sort !== null) {
      this.sortedData = JSON.parse(header_sort)
    }
    this.$store.dispatch(GET_CURRENT_MONTH)
        .then(finish => {
          if (finish) {
            this.current_month = this.legacy ? this.$store.getters.getCurrentMonthLegacy : this.$store.getters.getCurrentMonth
            this.fetchHeaders()
          }
        })
  }
}
</script>

<style scoped lang="scss">
.row-card {
  div > div:nth-child(1) {
    font-size: .68rem;
    font-weight: 400;
    color: #757575
  }

  div > div:nth-child(2) {
    font-size: .84rem;
    color: #4a4a4a;

    span:nth-child(1) {
      font-weight: 500;
      color: #2a9d2f;
    }

    span:nth-child(2) {
      font-weight: 500;
      color: #2d2d2d;
    }
  }
}
</style>